<template>
  <div class="onboarding__container">
    <h1 class="smalltitle onboardingtitle">
      {{ current_user.username }}, we are setting up Carl-bot in
      {{ current_guild.name }}
    </h1>
    <div class="underlined__title">
      <h4 class="smalltitle">AUTOMOD</h4>
    </div>
    <div>
      <b-card class="onboarding__card" style="margin-bottom: 1px">
        <h4 class="teenytinytitle">Set up automated moderation</h4>
        <div class="form-group-check">
          <div class="form-group-check-inner">
            <label class="tinytitle sidelined">
              <input type="radio" value="none" v-model="amTemplate" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              Off/Custom
            </label>
            <div class="preset__hint">(You can always change it later)</div>
          </div>
          <div class="form-group-check-inner">
            <label class="tinytitle sidelined">
              <input type="radio" value="low" v-model="amTemplate" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              Minimal
            </label>
            <div class="preset__hint">Mutes</div>
          </div>
          <div class="form-group-check-inner">
            <label class="tinytitle sidelined">
              <input type="radio" value="medium" v-model="amTemplate" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              Medium
            </label>
            <div class="preset__hint">
              Good fit for most. Will keep your server in a nice state.
            </div>
          </div>
          <div class="form-group-check-inner">
            <label class="tinytitle sidelined">
              <input type="radio" value="high" v-model="amTemplate" />
              <svg class="checker" height="16" width="16">
                <circle cx="8" cy="8" r="3" fill="inherit" stroke="none" />
                <circle
                  cx="8"
                  cy="8"
                  r="6"
                  stroke="inherit"
                  strokeWidth="2"
                  fill="none"
                />
              </svg>
              High
            </label>
            <div class="preset__hint">
              For servers with high maintenance users.
            </div>
          </div>
        </div>
      </b-card>
      <b-card class="onboarding__card" style="margin-bottom: 1px">
        <p></p>
        <i v-show="amTemplate === 'none'"
          >Note: if none of the presets fit your needs, you can configure
          everything in detail later.</i
        >
      </b-card>
    </div>
    <div class="onboarding__footer">
      <button class="saucy-btn" v-on:click="doUpdate">Finish</button>
      <button class="saucy-btn-alt" v-on:click="goBack">Previous step</button>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import { ClientTable, Event } from "vue-tables-2";
import { createTags, VueTagsInput } from "@johmun/vue-tags-input";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast,
};

Vue.use(VueNotifications, options);

export default {
  name: "dashboard",
  components: {
    Callout,
    cSwitch,
    VueTagsInput,
    Multiselect,
  },
  data: function () {
    return {
      amTemplate: "none",
    };
  },
  notifications: {
    showChannelWarningMessage: {
      type: VueNotifications.types.error,
      title: "Missing field",
      message: "You need to select a channel when using that mode.",
    },
  },
  computed: {
    current_user() {
      return this.$store.state.discord_user || {};
    },
    current_guild() {
      return this.$store.state.discord_guilds.find(
        (x) => x.id == this.$route.params.guild_id
      );
    },
  },
  methods: {
    async doUpdate() {
      const guild_id = this.$route.params.guild_id;
      let greet_message;
      if (this.amTemplate === "none" || !this.amTemplate) {
        this.$router.push(`/onboarding/${guild_id}/final`);
        return;
      }

      await this.axios.put(`/api/v1/servers/${guild_id}/onboarding/automod`, {
        template: this.amTemplate,
      });
      this.$router.push(`/onboarding/${guild_id}/final`);
    },
    goBack() {
      const guild_id = this.$route.params.guild_id;

      this.$router.push(`/onboarding/${guild_id}/moderation`);
    },
    parseColor(r) {
      return r.color != null ? "#" + r.color.toString(16) : "#fefefe";
    },
    intToHex(int) {
      if (!int) {
        return "eee";
      }
      return int.toString(16);
    },

    fetchConfig() {
      this.$Progress.start();
      this.$store.commit("onboardingStage", 4);
      this.axios
        .get(
          "/api/v1/servers/" +
            this.$route.params.guild_id +
            "/moderation?onboarding=true"
        )
        .then((r) => {
          this.old_settings = r;
          this.greet_channel = r.greet_channel;
          this.greet_message = r.greet_message;
          if (r.greet_message !== "") {
            this.greet_mode = "custom";
          }
          this.$Progress.finish();
        });
    },
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.fetchConfig();
  },
  created: function () {
    this.fetchConfig();
  },
};
</script>

<style src="spinkit/spinkit.min.css"></style>
<style scoped>
.preset__hint {
  padding-left: 5px;
}

.prefix__container {
  display: flex;
  flex-direction: unset;
  flex-wrap: wrap;
}
.prefix__example {
  background-color: #292a33;
  padding: 3px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 4px;
  font-weight: 500;
  border-radius: 3px;
}
</style>
<style>
.vue-tags-input {
  background: #505b64;
}

.vue-tags-input .ti-new-tag-input {
  background: transparent;
  color: #b7c4c9;
}

div.vue-tags-input {
  background: #505b64 !important;
}

.vue-tags-input .ti-input {
  padding: 4px 10px;
  transition: border-bottom 200ms ease;
  border: 1px solid #222823;
}

/* we cange the border color if the user focuses the input */
.vue-tags-input.ti-focus .ti-input {
  border: 1px solid #22222a;
}

/* some stylings for the autocomplete layer */
.vue-tags-input .ti-autocomplete {
  background: #283944;
  border-top: none;
}

/* the selected item in the autocomplete layer, should be highlighted */
.vue-tags-input .ti-item.ti-selected-item {
  background: #ebde6e;
  color: #283944;
}

/* style the placeholders color across all browser */
.vue-tags-input ::-webkit-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input ::-moz-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-ms-input-placeholder {
  color: #a4b1b6;
}

.vue-tags-input :-moz-placeholder {
  color: #a4b1b6;
}

/* default styles for all the tags */
.vue-tags-input .ti-tag {
  position: relative;
  background: #292a33;
  color: #eee;
}

/* we defined a custom css class in the data model, now we are using it to style the tag */
.vue-tags-input .ti-tag.custom-class {
  background: transparent;
  border: 1px solid #292a33;
  color: #292a33;
  margin-right: 4px;
  border-radius: 0px;
  font-size: 13px;
}

/* the styles if a tag is invalid */
.vue-tags-input .ti-tag.ti-invalid {
  background-color: #e88a74;
}

/* if the user input is invalid, the input color should be red */
.vue-tags-input .ti-new-tag-input.ti-invalid {
  color: #e88a74;
}

/* if a tag or the user input is a duplicate, it should be crossed out */
.vue-tags-input .ti-duplicate span,
.vue-tags-input .ti-new-tag-input.ti-duplicate {
  text-decoration: line-through;
}

/* if the user presses backspace, the complete tag should be crossed out, to mark it for deletion */
.vue-tags-input .ti-tag:after {
  transition: transform 0.2s;
  position: absolute;
  content: "";
  height: 2px;
  width: 108%;
  left: -4%;
  top: calc(50% - 1px);
  background-color: #000;
  transform: scaleX(0);
}

.vue-tags-input .ti-deletion-mark:after {
  transform: scaleX(1);
}

.onboarding__container {
  display: flex;
  align-items: center;
  background-color: #22222a;
  flex-direction: column;
  flex-grow: 1;
  flex-wrap: wrap;
}

.underlined__title {
  border-bottom: 4px solid #49d6df;
  padding-left: 10px;
  padding-right: 10px;
  display: inline-flex;
  align-self: center;
}
</style>